/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import {
  useAppStore,
} from '@/store/app';
import {
  useAuthStore,
} from '@/store/auth';

interface IAppUseStore {
  app: ReturnType<typeof useAppStore>;
  auth: ReturnType<typeof useAuthStore>;
}

const useStore = {
} as IAppUseStore;

const registerStore = (): void => {
  useStore.app = useAppStore();
  useStore.auth = useAuthStore();
};

const resetStore = (): void => {
  useAppStore().$reset();
  useAuthStore().$reset();
};

export {
  registerStore,
  resetStore,
  useStore,
};

// NOTE: For hot module replacement see https://pinia.vuejs.org/cookbook/hot-module-replacement.html
