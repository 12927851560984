<template>
  <div class="relative min-h-screen flex-col">
    <main>
      <BusNotification />
      <div>
        <CustomLoader1
          v-if="appStore.isLoading"
          class="modal modal-open"
        />
        <RouterView />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import BusNotification from '@/components/App/BusNotification.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  viewportBreakpoint,
} from '@/helpers';
import {
  useStore,
} from '@/store';

let resizedBreakpointEventHandler: (e: Event) => void;

const appStore = useStore.app;

onBeforeMount(() => {
  resizedBreakpointEventHandler = (): void => {
    const resizedBreakpoint = viewportBreakpoint();

    if (resizedBreakpoint !== appStore.breakpoint) {
      appStore.breakpoint = resizedBreakpoint;
    }
  };

  window.addEventListener('resize', resizedBreakpointEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizedBreakpointEventHandler);
});
</script>
